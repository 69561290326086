<script lang="ts" setup>
import { BaseValidation } from '@vuelidate/core'
import { onClickOutside, useWindowScroll, useWindowSize, watchPausable } from '@vueuse/core'

export type OptionValue = string | number | boolean

export type SelectOption = {
  value: OptionValue
  label: string
  selectedLabel?: string
  isDisabled: boolean
  icon?: string
  customAttrs?: Record<string, any>
}

const props = withDefaults(
  defineProps<{
    label?: string
    mobileLabel?: string
    id: string
    placeholder?: string
    modelValue?: OptionValue
    options: SelectOption[]
    displaySelectedIcon?: boolean
    class?: string
    heightClass?: string
    optionClass?: string
    optionsClass?: string
    disabled?: boolean
    validation?: BaseValidation
    appended?: 'left' | 'right'
    isInput?: boolean
    renderSelection?: (option?: SelectOption) => Component
    chevronIcon?: boolean
  }>(),
  {
    label: undefined,
    mobileLabel: undefined,
    isInput: true,
    placeholder: '',
    class: '',
    heightClass: '',
    optionClass: '',
    optionsClass: '',
    displaySelectedIcon: false,
    disabled: false,
    appended: undefined,
    renderSelection: undefined,
    chevronIcon: true,
  },
)

const emit = defineEmits(['update:modelValue'])

const { t } = useI18n()
const isMobile = useIsMobile()
const dropdownPosition = ref({
  left: '0',
  top: '0',
  width: '0',
})
const optionsContainerRef = ref()
const inputRef = ref()
const isOpen = ref(false)
const { x, y } = useWindowScroll()
const { width, height } = useWindowSize()
const setDropdownPosition = () => {
  const { left, top, width } = getDropdownPosition()

  dropdownPosition.value.left = left ?? '0'
  dropdownPosition.value.top = top ?? '0'
  dropdownPosition.value.width = width ?? '0'
}
const { pause, resume } = watchPausable([x, y, width, height, isOpen], setDropdownPosition, {
  immediate: false,
})
pause()

const toggle = () => {
  if (props.disabled) return

  isOpen.value = !isOpen.value
  isOpen.value ? resume() : pause()
}

const select = (value: OptionValue) => {
  emit('update:modelValue', value)
  close()
}

const close = () => {
  isOpen.value = false
  pause()
}

const selectedOption = computed(() =>
  props.options.find((option) => option.value === props.modelValue),
)

const isOptionSelected = (option: SelectOption) => option.value === selectedOption.value?.value

const getDropdownPosition = () => {
  const el = inputRef.value as HTMLElement
  if (!el) return {}
  const rect = el.getBoundingClientRect()
  const toPx = (val: number) => `${val}px`

  return {
    left: toPx(rect.left),
    top: toPx(rect.top),
    width: toPx(rect.width),
  }
}

onClickOutside(optionsContainerRef, () => {
  if (!isMobile.value) {
    isOpen.value = false
  }
})

defineExpose({
  close,
})
</script>

<template>
  <ClientOnly>
    <ul
      class="dropdown relative"
      :aria-disabled="props.disabled"
      :class="props.class"
      role="listbox"
    >
      <div class="flex flex-col gap-2">
        <li v-if="props.label" :id="props.id" class="text-sm font-medium text-gray-350">
          {{ props.label }}
        </li>

        <li
          ref="inputRef"
          class="field flex h-12 select-none items-center justify-between rounded-full text-sm font-medium outline-1 outline-offset-0"
          :class="{
            'cursor-not-allowed !border-transparent bg-gray-200 text-gray-300': props.disabled,
            'text-gray-400': !props.disabled,
            '!border-red-700': props.validation?.$errors?.length,
            'rounded-r-none !pr-2': props.appended === 'left',
            'rounded-l-none !pl-4': props.appended === 'right',
            'border-1 px-6 py-3': props.isInput,
          }"
          role="button"
          :aria-labelledby="props.id"
          tabindex="0"
          @click.stop.prevent="toggle"
        >
          <div class="flex items-center gap-2">
            <template v-if="props.renderSelection">
              <component :is="props.renderSelection(selectedOption)" />
            </template>
            <template v-else-if="selectedOption">
              <RIcon
                v-if="props.displaySelectedIcon && selectedOption?.icon"
                :name="selectedOption?.icon"
                size="22"
              />
              {{ selectedOption?.selectedLabel || selectedOption?.label }}
            </template>
            <span v-else class="text-gray-300">{{ props.placeholder }}&nbsp;</span>
          </div>

          <RTransition name="grow-fade" :delay="300">
            <RIcon
              v-if="props.chevronIcon"
              size="22"
              class="caret transition-all duration-300"
              :class="{ '-rotate-180': isOpen, '!text-gray-300': props.disabled }"
              name="ic:round-keyboard-arrow-down"
            />
          </RTransition>
        </li>

        <div v-if="props.validation?.$errors?.length" class="mt-2 text-[12px] text-red-700">
          {{ props.validation?.$errors?.[0]?.$message }}
        </div>
      </div>

      <RTransition name="fade">
        <li
          v-if="!isMobile && isOpen"
          ref="optionsContainerRef"
          :aria-expanded="isOpen"
          :aria-hidden="!isOpen"
          role="list"
          class="options-container fixed z-30 rounded-[23px] border-1 pt-12 shadow-md"
          :class="{
            'rounded-tr-none': props.appended === 'left',
            'rounded-tl-none': props.appended === 'right',
          }"
          :style="{
            left: dropdownPosition.left,
            top: dropdownPosition.top,
            width: dropdownPosition.width,
          }"
        >
          <button
            v-if="isOpen"
            class="absolute left-0 top-0 flex h-12 w-full items-center justify-end rounded-3xl bg-transparent"
            :aria-label="t('globals.close')"
            @click="close"
          ></button>
          <ul
            class="options flex max-h-80 flex-col gap-2 overflow-auto rounded-b-3xl bg-white p-4"
            :class="props.optionsClass"
            role="option"
          >
            <li
              v-for="option in props.options"
              :id="`option-${option.value}`"
              :key="option.value.toString()"
              class="option cursor-pointer rounded-full px-4 py-[6px] text-[12px] font-bold text-gray-400 outline-1 transition-all duration-300 hover:bg-gray-100"
              :class="props.optionClass"
              tabindex="0"
              @click="select(option.value)"
              @keydown.space.prevent.stop="select(option.value)"
              @keydown.enter.prevent.stop="select(option.value)"
            >
              <div class="flex items-center justify-between">
                <div class="flex items-center gap-2">
                  <RIcon v-if="option?.icon" :name="option?.icon" size="22" />
                  {{ option.label }}
                </div>

                <RIcon
                  v-if="isOptionSelected(option)"
                  class="selected"
                  name="ic:round-check"
                  size="22"
                />
              </div>
            </li>
          </ul>
        </li>
      </RTransition>

      <RBaseDrawer
        v-if="isMobile"
        v-model:show="isOpen"
        v-bind="$attrs"
        placement="bottom"
        :height-class="props.heightClass ?? 'h-auto'"
        padding-class="p-4"
        background-class="bg-white"
        with-backdrop
        :title="props.label || props.mobileLabel"
        text-class="text--primary !text-base"
        @close="close"
      >
        <ul
          class="options flex max-h-[90%] flex-col gap-3 overflow-scroll p-0"
          :class="{ 'pb-4': !$slots['drawer-footer'] }"
          role="option"
        >
          <li
            v-for="option in props.options"
            :id="`option-${option.value}`"
            :key="option.value.toString()"
            class="option min-h-10 flex cursor-pointer items-center rounded-xl bg-gray-100 px-4 text-sm transition-all duration-300"
            :class="{
              'option--selected': isOptionSelected(option),
              'text-gray-400 ': !isOptionSelected(option),
            }"
            tabindex="0"
            @click="select(option.value)"
            @keydown.space.prevent.stop="select(option.value)"
            @keydown.enter.prevent.stop="select(option.value)"
          >
            <div class="flex h-10 items-center gap-2">
              <RIcon
                v-if="option?.icon"
                :name="isOptionSelected(option) ? 'ic:round-check' : option?.icon"
                size="22"
              />
              {{ option.label }}
            </div>
          </li>
        </ul>
        <div v-if="$slots['drawer-footer']">
          <div class="my-4 h-[1px] w-full bg-gray-100" />
          <slot name="drawer-footer" />
        </div>
      </RBaseDrawer>
    </ul>
  </ClientOnly>
</template>

<style scoped>
.options-container {
  border-color: var(--color-primary);
}
.option--selected {
  background-color: var(--color-primary) !important;
  color: white !important;
}

.field,
.option {
  outline-color: var(--color-primary);
}

.selected,
.caret {
  color: var(--color-primary);
}
</style>
